import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { notFoundStyles } from 'styles/notFoundStyles';

export function PageNotFound404(): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Grid container alignItems="center">
        <Typography variant="h2" sx={notFoundStyles.grayNumber}>
          404
        </Typography>
        <Typography variant="h3" sx={notFoundStyles.subTitle}>
          {t('not_found.could_not_found')} <br />
          {t('not_found.not_be_found')}
        </Typography>
      </Grid>
      <Grid container>
        <Typography variant="subtitle1" sx={notFoundStyles.description}>
          {t('not_found.description')}
        </Typography>
      </Grid>
      <Grid container>
        <Link href="/">
          <Button
            variant={'contained'}
            size={'small'}
            type="button"
            sx={notFoundStyles.button}
          >
            {t('not_found.go_to_advisor_page').toUpperCase()}
          </Button>
        </Link>
      </Grid>
    </>
  );
}
