export const notFoundStyles = {
  grayNumber: {
    color: '#878C99',
    fontWeight: 500,
    fontSize: '96px',
    mr: '30px'
  },
  subTitle: {
    color: '#000000',
    letterSpacing: '0.25px',
    fontSize: '34px',
    fontWeight: '500'
  },
  description: {
    color: '#092241',
    fontSize: '16px',
    fontWeight: 400,
    mt: '40px',
    mb: '20px'
  },
  button: {
    backgroundColor: '#1278BC !important',
    textDecoration: 'none'
  }
} as const;
