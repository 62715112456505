import Head from 'next/head';
import type { ReactElement } from 'react';
import { Layout } from 'components/Layout/Layout';
import { PageNotFound404 } from 'components/PageNotFound404';
import { layoutStyles } from 'styles/layoutStyles';
import staticProps from 'utils/translationHelper';

export default function PageNotFound(): React.ReactElement {
  return (
    <>
      <Head>
        <title>404</title>
        <meta name="description" content="Advisors Mortgage Group" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <PageNotFound404 />
    </>
  );
}

export const getStaticProps = staticProps;

PageNotFound.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout fullWidth isLoanForm={false} sxWrapper={layoutStyles.admin}>
      {page}
    </Layout>
  );
};
